<template>
    <v-container class="pt-5 pb-0 px-0" fluid>
        <v-container  class="content mb-6" fluid>
            <Headline text="teams" />

            <v-row no-gutters>
                <v-container grid-list-xl>
                    <v-layout wrap justify-space-around>
                        <v-flex shrink v-for="(team, i) in $db.teams.public" :key="i">
                            <Box-Team  v-bind="team" />
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-row>
        </v-container>

        <Banner />

        <!--v-container class="content mt-5">
            <Headline text="letzte ergebnisse" />
            
            <v-row no-gutters>
                <v-container grid-list-xl>
                    <v-layout wrap justify-space-around>
                        <v-flex grow v-for="(match, i) in matches.slice(0, maxMatches)" :key="i">
                            <Box-Match v-bind="match" />
                        </v-flex>
                    </v-layout>
                </v-container>
            </v-row>
        </v-container-->
        
    </v-container>
</template>

<script>
    import Banner from '@/objects/Banner.vue'
    import BoxTeam from '@/objects/Box-Team.vue'
    //import BoxMatch from '@/objects/Box-Match.vue'

    export default {
        name: 'home',
        props: [ 'teams', 'matches' ],
        data: function() {
            return {
                
            };
        },
        computed: {
            maxMatches: function() {
                return 6;
            },
            playerImageStyle () {
                return { backgroundImage: 'url(' + (this.image != null ? this.image : require('@/assets/trikot_aXz.png')) + ')'}
            }
        },
        components: {
            Banner,
            BoxTeam,
            //BoxMatch,
        },
        mounted (){
            this.$db.teams.loadPublic();
        }
    }
</script>

<style scoped>

</style>
<template>
    <v-card class="box-team" tile flat>
        <v-row no-gutters justify="center">
            <span class="text-h6 font-weight-regular mt-5">{{gameshort}} {{name}}</span>
            <span class="text-body-2 text-uppercase accent--text">{{gamename}}</span>
        </v-row>
        <v-row no-gutters justify="center" class="py-10">
            <v-hover v-slot="{ hover }" v-for="(member, i) in members" :key="i">
                <v-avatar size="44" class="mx-2" color="box_accent">
                    <axz-img dummy="trikot_aXz.png" /> <!-- :id="member.image" -->
                    <!-- :style="hover ? 'border: 2px solid; border-color: var(--v-accent-base);' : ''" -->
                </v-avatar>
            </v-hover>
        </v-row>
        
        <v-row no-gutters class="team-info">
            <v-col>
                <span class="text-body-2 text-uppercase mt-1 ml-6 grey--text">from</span>
                <span class="text-body-2 text-uppercase ml-6">germany</span>
            </v-col>
            <v-col>
                <v-btn tile block class="" height="100%" light :to="{ name: 'teamItem', params: { team_id: ID } }">
                    <span>team-site</span>
                    <v-icon small right>fas fa-chevron-right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
//import Box from '@/objects/Box.vue';

export default {
    name: "box-team",
    props: [ 'ID', 'name', 'gamename', 'gameshort', 'members' ],
    components: {
        //Box
    }
    
}
</script>

<style scoped>
.box-team {
    width: 376px;
    text-align: center;
}

.box-team span {
    display: block;
    width: 100%;
}

.box-team .team-player{
    background-color: var(--v-box_accent-base);
    width: 44px;
    height: 44px;
    /*margin: 0px auto;*/
    border-radius: 22px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 6px;
}

.box-team .team-player:hover {
    background-color: var(--v-accent-base);
}

.box-team .team-info{
    background-color: var(--v-box_accent-base);
    text-align: left;
}

</style>